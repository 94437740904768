import { useState } from "react";
import { TextParagraphs } from "./TextParagraphs";
import { textContent, breackpoints } from "../services/constants";
import { Icon } from "@iconify/react/dist/iconify.js";

export const Main = () => {
  const [expanded, setExpanded] = useState(false);
  const togleExpand = () => setExpanded((state) => !state);

  const vw = window.innerWidth;

  return (
    <div id="main" className="px-4 relative mt-6 md:mb-2">
      <div
        className={`  md:max-h-fit  w-full md:flex md:flex-row `}
        onClick={vw < breackpoints.md ? togleExpand : () => {}}
      >
        <img
          src="../images/pexels-daniel-zbroja-3348066-27530585.webp"
          alt="Rome"
          width={200}
          className="rounded-2xl mx-auto md:w-2/5 md:h-full mb-1 animate-slow-appear"
          loading="lazy"
        />
        <TextParagraphs
          text={textContent.main}
          expanded={expanded}
          showStrings={1}
        />
      </div>
      {vw < breackpoints.md && (
        <p className="flex justify-center w-full" onClick={togleExpand}>
          {expanded ? (
            <Icon
              icon="mi:chevron-double-up"
              className="animate-slow-bounce text-xl"
            />
          ) : (
            <Icon
              icon="mi:chevron-double-down"
              className="animate-slow-bounce text-xl"
            />
          )}
        </p>
      )}
    </div>
  );
};
