import { useState } from "react";
import { Collapse } from "@mui/material";
import { NavLink } from "react-router-dom";

export const Card = ({ title, description, image, plan, costs }) => {
  const [expanded, setExpanded] = useState(false);
  const togleExpanded = () => setExpanded((state) => !state);
  return (
    <div className="bg-customWhite rounded-lg shadow-lg overflow-hidden transform transition duration-300 flex flex-col">
      <img
        src={image}
        alt={title}
        className="w-full h-48 md:h-96 object-cover"
      />
      <div className="p-6 grow flex flex-col ">
        <h2 className="text-2xl font-semibold mb-2 ">{title}</h2>
        <p
          className={`text-gray-700 text-base md:text-lg mb-4 ${
            !expanded && "line-clamp-3"
          }`}
        >
          {description}
        </p>
        <Collapse in={expanded} timeout={600} unmountOnExit>
          {plan && (
            <p className="text-gray-700 text-sm md:text-base mb-4">
              Ми побачимо: {plan.join(" - ")}
            </p>
          )}
          {costs.map((el, idx) => (
            <div key={idx}>
              <p className="text-gray-700 text-xl md:text-2xl mb-4">
                {el.time}
              </p>

              <p className="text-gray-700 text-xl md:text-2xl mb-4">
                {el.price}
              </p>
            </div>
          ))}
        </Collapse>
        <div className="flex justify-between">
          <NavLink
            to="https://t.me/festajoy"
            target="_blank"
            className="p-4 py-1 w-fit h-fit hover:cursor-pointer md:hover:bg-customActiveBgc bg-customBeige border border-black text-center my-1 rounded-full text-lg flex items-end md:py-2 md:text-xl md:font-medium md:border-2"
          >
            Забронювати
          </NavLink>
          <button
            onClick={togleExpanded}
            className=" p-4 py-1 w-fit h-fit hover:cursor-pointer md:hover:bg-customActiveBgc border border-black text-center my-1 rounded-full text-lg flex items-end md:py-2 md:text-xl md:font-medium md:border-2"
          >
            {expanded ? "Згорнути" : "Більше"}
          </button>
        </div>
      </div>
    </div>
  );
};
