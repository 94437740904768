import { Icon } from "@iconify/react/dist/iconify.js";

export const Divider = () => {
  return (
    <div className="px-4 py-2 w-full flex justify-center items-center text-customBrown">
      <div className="flex-grow flex flex-col justify-center">
        <div className="border-b border-customBrown"></div>
        <div></div>
      </div>
      <Icon icon="game-icons:olive" className="ml-1" />
      <Icon icon="game-icons:olive" className="transform scale-x-[-1] mr-1" />
      <div className="flex-grow flex flex-col justify-center">
        <div className="border-b border-customBrown"></div>
        <div></div>
      </div>
    </div>
  );
};
