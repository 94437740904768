import { NavLink } from "react-router-dom";

export const SiteCredits = () => {
  return (
    <div className="text-white w-full pt-3 pb-2 text-[3vw] md:text-[1.2vw] flex justify-center items-center bg-[#20201f55]">
      © 2024 Olga Bidnenko | By{" "}
      <NavLink
        href="https://www.linkedin.com/in/oleksandr-kochenko/"
        target="_blank"
        rel="noreferrer"
        className="text-inherit text-[3vw] md:text-[1.2vw] underline underline-offset-2 block ml-1"
      >
        Alex Kochenko
      </NavLink>
    </div>
  );
};
