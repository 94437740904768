import { NavLink } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";

export const Footer = () => {
  return (
    <>
      <h3 className="font-h text-customBrown italic font-bold  text-3xl md:text-5xl md:py-6 md:text-center [text-shadow:_0.5px_0.5px_1px_#84735e,_-0.5px_-0.5px_0.5px_#f4eee3]">
        Trovami
      </h3>
      <div className="flex items-center py-2 md:text-xl md:pl-[30vw]">
        <NavLink to="https://www.instagram.com/olha.in.italy/" target="_blank">
          <Icon
            icon="simple-icons:instagram"
            className="text-xl md:text-2xl text-[#cd486b] md:text-inherit hover:text-[#cd486b] mr-3 animate-custom-shake-1"
          />
        </NavLink>
        <p>https://instagram.com/olha.in.italy</p>
      </div>
      <div className="flex items-center md:text-xl py-2 md:pl-[30vw]">
        <NavLink to="https://wa.me/393911431549" target="_blank">
          <Icon
            icon="ic:twotone-whatsapp"
            className="text-2xl md:text-3xl text-[#3b5998] md:text-inherit hover:text-[#cd486b] mr-3 animate-custom-shake-2 "
          />
        </NavLink>
        <p>+39 391 143 1549</p>
      </div>
      <div className="flex items-center md:text-xl py-2 md:pl-[30vw]">
        <NavLink to="https://t.me/festajoy" target="_blank">
          <Icon
            icon="ph:telegram-logo"
            className="text-2xl md:text-3xl text-[#24A1DE] md:text-inherit hover:text-[#24A1DE] mr-3 animate-custom-shake-3"
          />
        </NavLink>
        <p>@festajoy</p>
      </div>
      <div className="flex items-center md:text-xl py-2 md:pl-[30vw]">
        <NavLink to="mailto:olhainitaly@gmail.com" target="_blank">
          <Icon
            icon="proicons:mail"
            className="text-2xl md:text-3xl text-[#f2a60c] md:text-inherit hover:text-[#f2a60c] mr-3 animate-custom-shake-4"
          />
        </NavLink>
        <p>olhainitaly@gmail.com</p>
      </div>
    </>
  );
};
