import { Icon } from "@iconify/react/dist/iconify.js";
import { NavLink } from "react-router-dom";

export const Review = ({ data }) => {
  return (
    <div className="flex flex-col mb-5 md:mb-0">
      <div className="flex items-center justify-start md:mb-4">
        <NavLink to={data.link} target="_blank" rel="noopener noreferrer">
          <img
            src={`../images/${data.avatar}.jpg`}
            alt={data.profile}
            className="w-16 h-16 md:w-20 md:h-20 rounded-full border-2 border-customBrown"
          />
        </NavLink>
        <div className="ml-4">
          <NavLink
            to={data.link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-xl md:text-2xl font-h font-bold"
          >
            {data.profile}
          </NavLink>
          <p className="text-customGrey text-sm">{data.place}</p>
        </div>
      </div>
      <p className="text-sm md:text-base">
        <Icon
          icon="entypo:quote"
          className="inline text-xl md:text-2xl align-super text-customLightBrown"
        />
        {data.review}
      </p>
    </div>
  );
};
