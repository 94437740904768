import { tours } from "../services/constants";
import { Card } from "./Card";

export const Tours = () => {
  return (
    <div id="tours" className="px-4 relative mb-4">
      <h3 className="font-h text-customBrown italic font-bold mb-4 text-3xl md:text-5xl md:py-6 md:text-center [text-shadow:_0.5px_0.5px_1px_#84735e,_-0.5px_-0.5px_0.5px_#f4eee3]">
        Le impressioni
      </h3>
      <div className=" pt-0 grid grid-cols-1  md:grid-cols-2 md:w-3/4 md:mx-auto gap-6">
        {tours.map(({ title, description, image, costs, plan, id }) => (
          <Card
            key={id}
            title={title}
            description={description}
            image={image}
            costs={costs}
            plan={plan}
          />
        ))}
      </div>
    </div>
  );
};
