import { Icon } from "@iconify/react/dist/iconify.js";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export const Menu = ({ onClose }) => {
  return (
    <ul className="text-customWhite text-3xl py-1 px-5">
      <li>
        <ul className="flex py-3 items-center">
          <li className="w-[50px] " onClick={onClose}>
            <NavLink
              to="https://www.instagram.com/olha.in.italy/"
              target="_blank"
            >
              <Icon
                icon="simple-icons:instagram"
                className="hover:text-[#cd486b]"
              />
            </NavLink>
          </li>
          <li className="w-[50px] " onClick={onClose}>
            <NavLink to="https://t.me/festajoy" target="_blank">
              <Icon
                icon="ph:telegram-logo"
                className="text-[36px] hover:text-[#24A1DE]"
              />
            </NavLink>
          </li>
          <li className="w-[50px]" onClick={onClose}>
            <NavLink to="mailto:olhainitaly@gmail.com" target="_blank">
              <Icon
                icon="proicons:mail"
                className="text-[41px] hover:text-[#f2a60c]"
              />
            </NavLink>
          </li>
        </ul>
      </li>
      <li
        className="my-3 hover:underline hover:underline-offset-8"
        onClick={onClose}
      >
        <HashLink to="#about" smooth>
          Про мене
        </HashLink>
      </li>
      <li
        className="my-3 hover:underline hover:underline-offset-8"
        onClick={onClose}
      >
        <HashLink to="#tours" smooth>
          Екскурсії
        </HashLink>
      </li>
      {/* <li
        className="my-3 hover:underline hover:underline-offset-8"
        onClick={onClose}
      >
        <HashLink to="#blog" smooth>
          Блог
        </HashLink>
      </li> */}
      <li
        className="my-3 hover:underline hover:underline-offset-8"
        onClick={onClose}
      >
        <HashLink to="#reviews" smooth>
          Відгуки
        </HashLink>
      </li>
      <li
        className="my-3 hover:underline hover:underline-offset-8"
        onClick={onClose}
      >
        <HashLink to="#footer" smooth>
          Контакти
        </HashLink>
      </li>
    </ul>
  );
};
