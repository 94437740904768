import { Collapse } from "@mui/material";
import { breackpoints } from "../services/constants";

export const TextParagraphs = ({ text, expanded, showStrings, left }) => {
  const vw = window.innerWidth;
  const textArr = text.split("\n");
  return (
    <div
      className={`px-0 ${
        left ? "md:pr-4" : "md:pl-4"
      } text-[4vw] md:text-[1.5vw] md:w-3/5`}
    >
      {textArr.slice(0, showStrings).map((el, idx) => (
        <p className={`py-[0.15rem] md:py-1 `} key={idx}>
          {el}
        </p>
      ))}

      <Collapse
        in={expanded || vw > breackpoints.md}
        timeout={600}
        unmountOnExit
      >
        {textArr.slice(showStrings).map((el, idx) => (
          <p key={idx} className="py-[0.15rem] md:py-1">
            {el}
          </p>
        ))}
      </Collapse>
    </div>
  );
};
