import { useState } from "react";
import { reviews } from "../services/constants";
import { Review } from "./Review";
import { Collapse } from "@mui/material";

export const Reviews = () => {
  const [expanded, setExpanded] = useState(false);
  const togleExpand = () => setExpanded((state) => !state);
  return (
    <div id="reviews" className="px-4 relative md:mb-2">
      <h3 className="font-h text-customBrown italic font-bold mb-4 text-3xl md:text-5xl md:py-6 md:text-center [text-shadow:_0.5px_0.5px_1px_#84735e,_-0.5px_-0.5px_0.5px_#f4eee3]">
        La gloria
      </h3>
      <div className="w-full md:grid md:grid-cols-3 md:gap-10 md:px-20 mb-5 md:mb-10">
        {reviews.slice(0, 3).map((el, idx) => (
          <Review key={idx} data={el} />
        ))}
      </div>
      <Collapse in={expanded} timeout={600} unmountOnExit>
        <div className="w-full md:grid md:grid-cols-3 md:gap-10 md:px-20 mb-8 md:mb-10">
          {reviews.slice(3).map((el, idx) => (
            <Review key={idx} data={el} />
          ))}
        </div>
      </Collapse>
      <button
        onClick={togleExpand}
        className="-mt-3 md:-mt-5 p-4 py-1 w-fit h-fit hover:cursor-pointer md:hover:bg-customActiveBgc border border-black text-center my-1 rounded-full text-lg flex items-end md:py-2 md:text-xl md:font-medium md:border-2 mx-auto"
      >
        {expanded ? "Менше" : "Більше"}
      </button>
    </div>
  );
};
