import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Hero } from "./components/Hero";
import { Icon } from "@iconify/react/dist/iconify.js";
import { HashLink } from "react-router-hash-link";
import { Tours } from "./components/Tours";
import { About } from "./components/About";
// import { Blog } from "./components/Blog";
import { Reviews } from "./components/Reviews";
import { Divider } from "./components/Divider";
import { Footer } from "./components/Footer";
import { SiteCredits } from "./components/SiteCredits";
import { Main } from "./components/Main";

function App() {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <BrowserRouter>
      <header>
        <Hero />
      </header>
      <main>
        <Main />
        <Divider />
        <About />
        <Divider />
        <Tours />
        {/* <Divider />
        <Blog /> */}
        <Divider />
        <Reviews />
        <Divider />
      </main>
      <footer id="footer" className="px-4 relative md:mb-2 ">
        <Footer />
      </footer>
      {scrollPosition > 30 && (
        <HashLink
          to="#"
          smooth
          className="fixed bottom-3 right-3 md:bottom-10 md:right-10 animate-slow-appear hover:bg-customActiveBgc text-customGrey text-4xl backdrop-blur-sm border-2 border-customGrey p-1 rounded-full flex items-center justify-center"
        >
          <Icon icon="line-md:chevron-double-up" />
        </HashLink>
      )}
      <SiteCredits />
    </BrowserRouter>
  );
}

export default App;
