import { useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Skeleton, SwipeableDrawer } from "@mui/material";
import { breackpoints, colors, textContent } from "../services/constants";
import { Menu } from "./Menu";
import { HashLink } from "react-router-hash-link";

export const Hero = () => {
  const vw = window.innerWidth;
  const vh = window.innerHeight;

  const [openDrawer, setOpenDrawer] = useState(false);
  const [loaded, setLoaded] = useState(false);

  return (
    <div className="relative">
      {!loaded && (
        <Skeleton
          variant="rectangular"
          width={vw}
          height={vw <= breackpoints.sm ? (vh / 3) * 2 : vh}
        />
      )}
      <img
        className={`w-screen brightness-75 md:brightness-100 ${
          !loaded && "hidden"
        }`}
        src={`../images/${
          vw <= breackpoints.sm ? "hero_image_vert.webp" : "hero_image.webp"
        }`}
        alt="Rome"
        onLoad={() => setLoaded(true)}
      />
      <div className="absolute bottom-2 w-full md:full md:pl-28 px-10  text-white font-bold [text-shadow:_1px_1px_2px_#000,_-0.5px_-0.5px_0.5px_#f4eee3]">
        <h1 className="text-3xl md:text-6xl italic font-h md:mb-8">
          {textContent.heading}
        </h1>
        <HashLink
          smooth
          to="#tours"
          className="p-4 py-2 w-fit hover:bg-customActiveBgc border border-white text-center my-1 rounded-full text-lg flex items-end md:mb-8 md:py-4 md:text-2xl md:font-medium md:border-2"
        >
          Обрати екскурсію{" "}
          <span className="animate-slow-bounce  pl-2">
            <Icon icon="mingcute:arrow-down-line" />
          </span>
        </HashLink>
      </div>
      <div
        className="absolute top-3 right-3 md:top-10 md:right-10 hover:cursor-pointer hover:bg-customActiveBgc text-white text-4xl backdrop-blur-sm md:border-2 border border-white p-1 rounded-full"
        onClick={() => setOpenDrawer(true)}
      >
        <Icon icon="mynaui:menu" />
      </div>
      <nav>
        <SwipeableDrawer
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          onOpen={() => setOpenDrawer(true)}
          anchor="right"
          PaperProps={{
            sx: {
              height: "fit-content",
              bgcolor: colors.customLightBrown,
              opacity: 0.9,
              padding: 2,
            },
          }}
        >
          <Menu onClose={() => setOpenDrawer(false)} />
        </SwipeableDrawer>
      </nav>
    </div>
  );
};
